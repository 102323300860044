import React from "react";

const Header = () => {
  return (
    <div className="contact-header">
      <div className="max-w-7xl mx-auto px-10 md:px-20">
        <h1 className="title text-white text-5xl md:text-6xl  pt-48 pb-4 text-center  ">
          Contact Us
        </h1>
      </div>
    </div>
  );
};

export default Header;
