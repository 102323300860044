import React from "react";
import picture from "../../images/sub-elite.webp";

const Contact = () => {
  return (
    <div className="w-full bg-lightGray">
      <div className="flex flex-col md:flex-row">
        <div className="mx-4 md:mx-0">
          <img
            style={{ height: "500px", width: "700px", objectFit: "cover" }}
            src={picture}
            alt="picture"
          />
        </div>
        <div className="md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
          <h1 className="text-black text-xl title pb-6">Contact Us</h1>
          <p className="h-1 bg-blue-500 w-8 mb-8"></p>

          <p className="text-primary unlock-title mb-14">
            Please email us at info@astraintel.co if you have any questions or
            are interested in learning more.
          </p>
          <a
            href="mailto:info@astraintel.co"
            className="text-black mb-10 text-lg px-10 py-4 font-semibold border-2 border-black cursor-pointer hover:text-blue-500 hover:border-blue-500 transition-all"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
